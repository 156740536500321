var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"value":_vm.dialog,"width":"1200px","persistent":""}},[_c('v-card',{staticClass:"px-2 pb-2 pt-4"},[_c('v-card-text',{staticClass:"px-3 pt-4"},[_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"cols":"4"}},[_c('h2',[_vm._v("Configuração do Abono Temporário")])]),_c('v-col',[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{attrs:{"cols":"3"}},[_c('date-picker',{attrs:{"label":"Data de Início","allowed-dates":_vm.allowedDates,"date":_vm.date_init,"outline":""},on:{"update:date":function($event){_vm.date_init=$event}}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('date-picker',{attrs:{"label":"Data de Fim","allowed-dates":_vm.blockLastMonth,"date":_vm.date_final,"outline":""},on:{"update:date":function($event){_vm.date_final=$event}}})],1)],1)],1)],1),(_vm.productsList.length)?_c('v-data-table',{key:"index",attrs:{"footer-props":{ itemsPerPageOptions: [5, 10] },"headers":_vm.headers,"items":_vm.products},scopedSlots:_vm._u([{key:"item.product_type",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.getProductFromType(item.product_type).name)+" ")])],1)]}},{key:"item.total_mrr",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.parseCurrencyDefault(item.total_mrr))+" ")])],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])],1)]}},{key:"item.quantity_paid",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_c('field-toggle',{model:{value:(_vm.productsList[_vm.getIndex(item)].quantity_paid),callback:function ($$v) {_vm.$set(_vm.productsList[_vm.getIndex(item)], "quantity_paid", $$v)},expression:"productsList[getIndex(item)].quantity_paid"}})],1)],1)]}},{key:"item.amount_paid",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_c('field-toggle',{attrs:{"money":""},model:{value:(_vm.productsList[_vm.getIndex(item)].amount_paid),callback:function ($$v) {_vm.$set(_vm.productsList[_vm.getIndex(item)], "amount_paid", $$v)},expression:"productsList[getIndex(item)].amount_paid"}})],1)],1)]}},{key:"item.unitary_value",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('v-col',[_vm._v(" "+_vm._s(_vm.calculateUnitaryValue(item.total_mrr, item.slots, item.product_type))+" ")])],1)]}}],null,true)}):_vm._e(),_c('v-divider',{staticClass:"mx-2"})],1),_c('v-card-actions',{staticClass:"my-2"},[_c('mf-button',{attrs:{"text":"","color":"error","label":"Cancelar"},on:{"click":_vm.close}}),_c('v-spacer'),_c('mf-button',{attrs:{"disabled":_vm.isQuantityValid,"icon":"sync","color":"primary","label":" Atualizar valores"},on:{"click":_vm.saveTemporaryChurn}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }